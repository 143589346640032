@use "@scss/global.scss" as *;

.top-girl-card-skeleton {
  position: relative;
  min-width: 260px;
  min-height: 480px;
  width: 100%;
  height: 100%;
  max-width: 260px;
  max-height: 480px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .skeleton-animation {
    position: absolute;
    top: 50%;
    width: 88px;
    height: 600px;
    opacity: 0.3;
    background: $gradient-1;
    animation-timing-function: cubic-bezier(0.79, 0.02, 0.24, 0.99);
  }

  .image-skeleton {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 433px;
    background-color: $purple-9;

    .skeleton-animation {
      top: 50%;
      transform: rotate(15deg) translate(-180px, -50%);
      animation: gradient-animation-top 1s infinite;
      animation-delay: 100ms;
    }
  }

  .details-skeleton {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 42px;
    background-color: $purple-9;

    .skeleton-animation {
      top: 0px;
      transform: rotate(15deg) translate(-180px, -90%);
      animation: gradient-animation-bottom 1s infinite;
      animation-delay: 1ms;
    }
  }

  @keyframes gradient-animation-top {
    0% {
      transform: rotate(15deg) translate(-200%, -50%);
    }
    100% {
      transform: rotate(15deg) translate(250%, -50%);
    }
  }

  @keyframes gradient-animation-bottom {
    0% {
      transform: rotate(15deg) translate(-200%, -90%);
    }
    100% {
      transform: rotate(15deg) translate(250%, -90%);
    }
  }
}
