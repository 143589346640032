@use "@scss/global.scss" as *;

.online-users-badge {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 114px;
  gap: 8px;
  border-radius: 76px;
  background: rgba(0, 0, 0, 0.3);
  padding: 0;

  color: $purple-4;
  font-weight: $bold;
  text-align: right;
  font-size: 10px;
  line-height: 113%;
  text-transform: uppercase;

  &-description {
  }

  &-counter {
    position: absolute;
    right: 0;
    bottom: 10%;
    transform: translate(50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 52px;
    background: $purple-2;
    padding: 4px;
    min-width: 24px;
    color: $purple-10;
    font-weight: $semi-bold;
    font-size: 12px;
  }
}
