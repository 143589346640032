@use "@scss/global.scss" as *;

.failed-create-chat {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 650px;
  text-align: center;

  @include mq-max($md) {
    width: 100%;
  }
}
