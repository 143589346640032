@use "@scss/global.scss" as *;

.full-screen-slider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;

  .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
  }

  .slider-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .react-transform-wrapper {
      width: 100% !important;
      height: 100% !important;
    }

    .react-transform-component {
      cursor: pointer;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      user-select: none;
    }

    .slider-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grey-8;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $grey-7;
      }

      &.left-button {
        left: 10px;
      }

      &.right-button {
        right: 10px;
      }
    }

    .close-button {
      position: fixed;
      top: 0;
      right: 0;
      height: 60px;
      padding: 0 24px;
      background: $grey-8;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      font-size: 16px;
      font-weight: $semi-bold;
      line-height: 140%;
      text-transform: uppercase;
      color: $purple-1;
      transition: all 0.3s ease-in-out;

      svg {
        stroke: $purple-1;
      }

      &:hover {
        background: $grey-7;
      }
    }
  }
}
