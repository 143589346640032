@use "@scss/global.scss" as *;

.chat-view {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &-chats-list {
    padding-top: $navbar-h;
    background-color: $grey-9;
    min-width: 265px;
    max-width: 20%;
    width: 100%;
  }

  &-main {
    flex-grow: 1;
    width: 100%;
    padding-top: $navbar-h;
  }

  &-chat-girl-profile {
    min-width: 265px;
    max-width: 30%;
    width: 100%;
  }

  @include mq-max($md) {
    height: calc(100vh - $mobile-toolbar-h);

    &-chats-list,
    &-chat-girl-profile {
      display: none;
    }
  }

  @include mq-max($lg) {
    &-chat-girl-profile {
      display: none;
    }
  }
}
