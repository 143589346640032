@use "@scss/global.scss" as *;

.sticky-sidebar-layout {
  display: flex;

  .sidebar-part {
    top: 0px;
    position: sticky;
    width: 80px;
    height: 100dvh;
    flex-shrink: 0;
  }

  .main-part {
    height: 100%;
    flex-grow: 1;
    overflow-x: auto;
  }

  .mobile-toolbar-part {
    display: none;
  }

  @include mq-max($md) {
    flex-direction: column;

    .sidebar-part {
      display: none;
    }

    .main-part {
    }

    .mobile-toolbar-part {
      display: flex;
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      width: 100%;
      height: $mobile-toolbar-h;
      z-index: $mobile-toolbar-z-index;
    }
  }
}
