@use "@scss/global.scss" as *;

.chat-history-list {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;

  &-header {
    display: flex;
    flex-flow: column nowrap;
    gap: 24px;
    padding: 16px;
  }

  &-main {
    @include custom-scrollbar-0();
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    padding: 0 16px;
    margin: 24px 0 16px;
    overflow-y: auto;
  }

  &-loading {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 24px 0;

    .loading-text {
      text-transform: uppercase;
      font-weight: $semi-bold;
      color: $grey-5;
      margin-top: 24px;
    }
  }
}
