@use "@scss/global.scss" as *;

.mobile-menu {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  display: flex;
  background-color: rgba($purple-10, 0.6);

  &-box {
    position: fixed;
    bottom: 0px;
    height: 75%;
    width: 100%;
    background-color: $purple-10;
    padding: 0px $mobile-margin-x 24px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .mobile-menu-header {
      display: flex;
      width: 100%;
      align-items: center;

      .close-btn {
        text-transform: uppercase;
        padding: 18px 0;
        font-size: 14px;
        font-weight: $semi-bold;
        line-height: 140%;
        color: $purple-1;
        display: flex;
        align-items: center;
        transition: all 0.1s ease-in-out;

        svg {
          transition: stroke 0.1s ease-in-out;
        }

        &:hover {
          color: $purple-3;

          svg {
            stroke: $purple-3;
          }
        }
        &:active {
          color: $purple-5;

          svg {
            stroke: $purple-5;
          }
        }
      }
    }

    .mobile-menu-action {
      @include h4;

      display: flex;
      align-items: center;
      gap: 12px;
      color: $white-1;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.1s ease-in-out;

      svg path {
        transition: stroke 0.1s ease-in-out;
      }

      &:hover {
        color: $purple-3;

        svg path {
          stroke: $purple-3;
        }
      }
      &:active {
        color: $purple-5;

        svg path {
          stroke: $purple-5;
        }
      }
    }

    .mobile-menu-action-affiliation {
      position: relative;
      color: $grey-6;
      pointer-events: none;

      .coming-soon {
        position: absolute;
        right: 5px;
        bottom: 10px;
        transform: translate(100%, 100%);
        color: $purple-1;
        background: $purple-7;
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;

        font-size: 14px;
        font-weight: $bold;
        line-height: 140%;
        text-transform: uppercase;
      }
    }

    .mobile-menu-content {
      .social-links {
        margin-top: 80px;
      }
    }
  }

  @include mq-max($xs) {
    &-box {
      .mobile-menu-content {
        .social-links {
          margin-top: 40px;
        }
      }
    }
  }
}
