@use "@scss/global.scss" as *;

.chat-nav-card {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-between;
  gap: 8px;
  background: $grey-8;
  border-radius: 10px;
  width: 100%;
  height: 64px;
  cursor: pointer;
  border: 1px solid transparent;

  &-content {
    display: flex;
    align-items: center;
    gap: 8px;

    .chatter-image {
      border-radius: 50%;
      object-fit: cover;
      flex-shrink: 0;
    }

    .chatter-info {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      height: 40px;

      .nick,
      .last-message {
        @include text-ellipsis(1);
        color: $grey-5;
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 4px;

    .action-btn {
      @include no-select();
      width: 24px;
      height: 24px;
    }
  }

  &--active {
    border: 1px solid $purple-5;

    .chat-nav-card-content {
      .nick {
        color: $purple-1;
        font-weight: $semi-bold;
      }
      .last-message {
        color: $purple-2;
      }
    }
  }
}
