@use "@scss/global.scss" as *;

.chat-textarea {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: $grey-7;
  padding: 8px 16px;
  height: max-content;
  width: 100%;
  gap: 8px;
  min-height: 48px;

  &-textarea,
  &-adornments {
    background-color: transparent;
  }

  &-textarea {
    @include p0();
    flex-grow: 1;
    color: $purple-1;
    resize: none;
    overflow: hidden;
    box-sizing: border-box;

    &::placeholder {
      color: $grey-5;
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &-adornments {
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: max-content;
    height: 100%;
  }
}
