@use "@scss/global.scss" as *;

.send-button {
  @include no-select();

  width: max-content;
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-weight: $semi-bold;
  line-height: 140%; /* 19.6px */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 48px;
  max-width: max-content;
  border-radius: 10px;
  padding: 0px 12px;
  color: $purple-10;
  background-color: $purple-3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  svg {
    position: relative;
    top: 0%;
    stroke: $purple-10;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background-color: $purple-4;
  }

  &:active {
    background-color: $purple-5;
  }

  &:focus-visible {
    outline: 2px solid rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.full-width {
    width: 100%;
    max-width: unset;
  }
}
