@use "@scss/global.scss" as *;

.chat-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: $grey-7;
  padding: 8px 16px;
  height: max-content;
  width: 100%;
  gap: 8px;
  min-height: 48px;

  &-input,
  &-adornments {
    background-color: transparent;
  }

  &-input {
    @include p0();
    flex-grow: 1;
    color: $purple-1;

    &::placeholder {
      color: $grey-5;
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &-adornments {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
  }

  input[type="search"]::-webkit-search-cancel-button {
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    background: url("../../../../../public//images/icons/icon-close.svg") no-repeat 50% 50%;
  }
}
