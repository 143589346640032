@use "@scss/global.scss" as *;

.conversation {
  @include custom-scrollbar-0();

  padding: 16px 13px 16px 21px;
  overflow-y: auto;
  scroll-behavior: auto;

  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  height: 100%;

  .date-separator {
    text-align: center;
    color: $grey-4;
  }
}
