@use "@scss/global.scss" as *;

.fetching-prev-messages-badge {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
}
