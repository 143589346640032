@use "@scss/global.scss" as *;

.remove-chat-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 450px;
  gap: 24px;
  text-align: center;

  @include mq-max($md) {
    width: 100%;
  }
}
