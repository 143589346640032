@use "@scss/global.scss" as *;

.chats-view {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &-chats-list {
    padding-top: $navbar-h;
    background-color: $grey-9;
    min-width: 265px;
    max-width: 20%;
    width: 100%;
  }

  @include mq-max($md) {
    height: calc(100vh - $mobile-toolbar-h);

    &-chats-list {
      width: 100%;
      max-width: 100%;
      padding: $navbar-h 0 0;
    }
  }
}
