@use "@scss/global.scss" as *;

.message-typing {
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  &-content {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 24px;
    border-radius: 10px;
    width: auto;
    max-width: 70%;
  }

  &-sender {
    @include p2();
    color: $grey-5;
    text-transform: uppercase;
  }

  &--receiving {
    .message-typing-content {
      margin-right: auto;
      border-top-left-radius: 0;
      background: $purple-3;
      color: $purple-10;
    }

    .message-typing-sender {
      align-self: flex-start;
    }
  }

  &--sending {
    .message-typing-content {
      border-top-right-radius: 0;
      background: $purple-6;
      color: $purple-1;
      margin-left: auto;
    }

    .message-typing-sender {
      align-self: flex-end;
      margin-left: 0;
    }
  }
}
