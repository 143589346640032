@use "@scss/global.scss" as *;

.send-message-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  padding: 16px 11px;
  gap: 8px;
  height: max-content;
}
