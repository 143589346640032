@use "@scss/global.scss" as *;

.metamask-connector {
  &-button {
  }

  &-connected {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 20px;
    height: 60px;
    color: $purple-10;
    background-color: $purple-5;

    .status-info {
      text-transform: uppercase;
      font-weight: $semi-bold;
    }
  }

  @include mq-max($md) {
    &-button {
      padding: 0 8px !important;
    }

    &-connected {
      padding: 0 8px;

      .status-info {
        display: none;
      }
    }
  }
}
