@use "@scss/global.scss" as *;

.chat-girl-profile {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;

  &-slider {
    height: 50%;
  }

  &-info {
    @include custom-scrollbar-0();

    height: 50%;
    padding: 32px 16px;
    overflow-y: auto;

    .profile-info-header {
      margin-bottom: 24px;

      .category {
        text-transform: uppercase;
        margin-bottom: 16px;
      }
      .name {
      }
    }

    .profile-info-description {
    }

    .profile-info-personality {
      display: flex;
      flex-flow: row wrap;
      gap: 8px;
    }

    .breakLine {
      background: $grey-7;
      height: 1px;
      margin: 24px 0;
    }

    .profile-info-buttons {
      display: flex;
      flex-flow: column nowrap;
      gap: 16px;
    }
  }
}
