@use "@scss/global.scss" as *;

.chat-feed {
  width: 100%;
  height: 100%;
  background-image: url("../../../../../public/images/layouts/chat-background.svg");
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
  display: flex;
  flex-flow: column nowrap;

  &-header {
    background: $purple-10;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 80px;

    .profile-button {
      display: flex;
      align-items: center;
      gap: 8px;
      width: max-content;
      color: $purple-1;

      .profile-image {
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  &-conversation {
    overflow-y: auto;
    height: 100%;
  }

  &-send-message {
  }
}
